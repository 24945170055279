import React, { useState } from "react";
import { TypeText } from "../../../components/type-text/type-text";
import { gql, useMutation } from "@apollo/client";
import { MstrButton } from "../../../components/mstr-button/mstr-button";
import { IhAssistOutput, ReferenceDocumentEntity } from "../../../generated/graphql";
import { RefdocItem } from "../../../components/refdoc-item/refdoc-item";
import { StaggerList } from "../../../components/stagger-list/stagger-list";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { MsLoader } from "../../../components/header/screens/ms-loader/ms-loader";
import { FadeIn } from "../../../components/fade-in/fade-in";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const IS_WINDOW = window.location.host.indexOf("window") > -1;
const IS_LOCALHOST = window.location.host.indexOf("localhost") > -1;

// const SHOULD_SHOW_ASSISTANT_CHOICES = IS_LOCALHOST || IS_WINDOW;
const SHOULD_SHOW_ASSISTANT_CHOICES = false;

const assistants = [
    {
        id: "asst_rLLQCp5W2COX6wjHcKpLF7XC",
        name: "MS Project Disclosure",
        label: "Costco: Project Disclosures 2024",
    },
    {
        id: "asst_mWh6VTwSIRZ9cHaHr7v6shcs",
        name: "MS Table Query",
        label: "Graber Pricing",
    },
    {
        id: "asst_ZBm624UE0u0j3PjHaEAwkjUZ",
        name: "MS PDF Query",
        label: "Graber Price Group",
    },
  ];

const GET_ASSIST_DATA = gql`
  mutation IhAssist($message: String!, $asst_id: String!) {
    ihAssist(message: $message, asst_id: $asst_id) {
        documents {
            id
            document_name
            url
            reftype
        }
        initiative {
            id
            name
        }
        answer
    }
  }
`;

const SELF_ASSIGN_INITIATIVE = gql`
  mutation SelfAssignInitiativeToLearner($initiative_id: Int!) {
    selfAssignInitiativeToLearner(initiative_id: $initiative_id)
  }
`;

type iProps = {
    assignedInitiativeIds: number[];
    refetchInitiatives: () => Promise<void>;
    toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InlineAssistant = (props: iProps) => {

    const [showTextbox, setShowTextBox] = useState(false);
    const [message, setMessage] = useState("");
    const [answerTyped, setAnswerTyped] = useState(false);
    const [refdocHeadingTyped, setrefdocHeadingTyped] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showInitiativeDetail, setShowInitiativeDetail] = useState(false);

    const [showAssistantChoices, setShowAssistantChoices] = useState(false);
    const [selectedAssistantIndex, setSelectedAssistantIndex] = useState(0);

    const [selfAssignInitiative, { loading: loadingAssignInitiative }] = useMutation(
        SELF_ASSIGN_INITIATIVE,
        {
            update: async (cache, res) => {
                console.log("Got self assign initiative response: ", response);
                await props.refetchInitiatives();
                if (response) {
                    setTimeout(() => {
                        history.push(`/initiative-intro/${response.initiative.id}`);
                    }, 1000);
                }
            },
        }
    );

    const history = useHistory();

    const [response, setResponse] = useState<IhAssistOutput>();

    const [assist, { loading }] = useMutation(GET_ASSIST_DATA, {
        update: (cache, response) => {
            console.log("Assist Response: ", response);
            setShowTextBox(false);
            setResponse(response.data.ihAssist);
            setShowButton(false);
        },
    });

    const isInitiativeAssigned = response && response.initiative && props.assignedInitiativeIds.indexOf(+response.initiative.id) > -1 ? true : false;

    return (
        <div className="pb-4">

            {loadingAssignInitiative && <div className="fixed inset-0 flex items-center justify-center bg-white">
                <MsLoader />
            </div>}

            <div className="flex items-center">
                <div className="flex-1">
                    {!response && <TypeText delay={500} onComplete={() => {
                        setShowTextBox(true);
                    }}>
                        Need help or looking for a document?
                    </TypeText>}
                </div>
                {SHOULD_SHOW_ASSISTANT_CHOICES && <div className="text-blue-500 text-lg flex items-center" onClick={() => setShowAssistantChoices(!showAssistantChoices)}>
                    more
                    <ChevronDownIcon className="h-5 w-5" />
                </div>}
            </div>

            {showAssistantChoices && <div className="space-y-3 py-3 text-base">
                {assistants.map((assistant, index) => (
                    <div className="flex items-center space-x-2" onClick={() => setSelectedAssistantIndex(index)}>
                        <CheckIcon className={classNames(
                            "h-5 w-5 text-blue-500",
                            index === selectedAssistantIndex ? "" : "opacity-0"
                        )} />
                        <div>
                            {assistant.label}
                        </div>
                    </div>
                ))}
            </div>}

            {showTextbox && <motion.textarea
                animate={{ height: "auto" }}
                initial={{
                    height: "1px",
                }}
                transition={{
                    duration: 0.6,
                }}
                onAnimationComplete={() => {
                    setShowButton(true);
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full my-4 outline-none rounded-md"
                autoFocus={true}
                rows={2}
            />}

            {response && <div>
                <div className="text-base">
                    <TypeText onComplete={() => {
                        setAnswerTyped(true);
                    }}>
                        {response.answer}
                    </TypeText>
                </div>

                {response.documents && answerTyped && <div>

                    {response.documents.length > 0 && <TypeText onComplete={() => setrefdocHeadingTyped(true)}>
                        <div className="text-base pt-6">
                            {response.documents.length === 1 ?
                                "Here is a QuickFlow (or document) that will help:" : "Here are some QuickFlows (or documents) that will help:"
                            }
                        </div>
                    </TypeText>}

                    {response.documents.length === 0 && <TypeText onComplete={() => setShowInitiativeDetail(true)}>
                        <div className="text-base">
                        </div>
                    </TypeText>}

                    {refdocHeadingTyped && <StaggerList
                        onAnimationComplete={() => {
                            setShowInitiativeDetail(true);
                        }}
                        list={response.documents.map((document: ReferenceDocumentEntity) => (
                            <div style={{ paddingLeft: 10 }} key={document.id}>
                                <RefdocItem
                                    refdocCount={{}}
                                    id={document.id}
                                    name={document.document_name}
                                    url={document.url}
                                    type={document.reftype}
                                />
                            </div>
                        ))}
                    />}

                    <br />

                </div>}

                {showInitiativeDetail && <div className="text-base">
                    {response.initiative && <div>
                        <TypeText onComplete={() => {
                            setShowButton(true);
                        }}>
                            To get an even better understanding, try this initiative, <span className="font-bold text-blue-500 cursor-pointer" onClick={() => {
                                const isAlreadyAssigned = props.assignedInitiativeIds.indexOf(+response.initiative.id) > -1;

                                if (isAlreadyAssigned) {
                                    history.push(`/initiative-intro/${response.initiative.id}`);
                                } else {
                                    selfAssignInitiative({
                                        variables: {
                                            initiative_id: +response.initiative.id,
                                        }
                                    })
                                }
                            }}>{response.initiative.name}.</span>
                            <br />
                        </TypeText>
                    </div>}
                    {!response.initiative && <div>
                        <TypeText onComplete={() => {
                            setShowButton(true);
                        }}>
                            &nbsp;
                        </TypeText>
                    </div>}
                </div>}
            </div>}


            {showButton && <FadeIn>
                {!response && <MstrButton disabled={loading} showLoading={loading} text={"Go"} onClick={() => {
                    let messageToSend = message;

                    if(showAssistantChoices) {

                        // 1 "Costco: Project Disclosures 2024" (As it is, no change in message)
                        if (selectedAssistantIndex === 0) {
                            messageToSend = message;
                        }
                        
                        // 2 "Graber Pricing" (As it is, no change in message)
                        if (selectedAssistantIndex === 1) {
                            messageToSend = message;
                        }
                        
                        // 3 "Graber Price Group"
                        if (selectedAssistantIndex === 2) {
                            messageToSend = `In the given data, Product color may be present in multiple locations. When asked about the price group of specific product colors, please list every Price group where color is found, ensuring that no locations are omitted. {question}: '${message}'`;
                        }
                    }

                    assist({
                        variables: {
                            message: messageToSend,
                            asst_id: showAssistantChoices ? assistants[selectedAssistantIndex].id : "NA",
                        }
                    })
                }} />}

                {response && <div className="flex space-x-6 mt-6">
                    <MstrButton className="flex-1" text="New Request" onClick={() => {
                        setResponse(undefined);
                        setShowTextBox(false);
                        setMessage("");
                        setAnswerTyped(false);
                        setrefdocHeadingTyped(false);
                        setShowButton(false);
                        setShowInitiativeDetail(false);
                    }} />

                    <MstrButton className="flex-1" text="All Done" onClick={() => {
                        props.toggle(false);
                    }} />
                </div>}
            </FadeIn>}

        </div>
    )
}